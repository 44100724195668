import { color } from "framer-motion";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

const QuantitySelector = ({ product, onQuantityChange }) => {
  const [quantity, setQuantity] = useState(1); // Initial value set to 1
  const [actualPrice, setActualPrice] = useState(product.price);
  const [discountPrice, setDiscountPrice] = useState(product.price);

  useEffect(() => {
    const calculateDiscountedPrice = () => {
      if (product._id === "6632450450b3e83d0b476637") {
        let firstItemPrice = product.price;
        let secondItemPrice = product.price * 0.90;
        let thirdItemPrice = product.price * 0.70;
    
        if (quantity === 1) {
          return firstItemPrice;
        } else if (quantity === 2) {
          return firstItemPrice + secondItemPrice;
        } else if (quantity === 3) {
          return firstItemPrice + secondItemPrice + thirdItemPrice;
        } else {
          let additionalItemsPrice = (quantity - 3) * product.price * 0.70;
          return firstItemPrice + secondItemPrice + thirdItemPrice + additionalItemsPrice;
        }
      }
    
      const fullPriceTotal = product.price;
      const discountedPriceTotal =
        (quantity - 1) * product.price * (1 - product?.offer / 100);
      const totalPrice = fullPriceTotal + discountedPriceTotal;
    
      return totalPrice;
    };
    
    // Update the actual and discounted prices whenever the quantity changes
    setActualPrice(product.price * quantity);
    setDiscountPrice(Math.trunc(calculateDiscountedPrice()));
  }, [quantity, product]);

  const decreaseQty = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
      onQuantityChange(quantity - 1);
    }
  };

  const increaseQty = () => {
    if (quantity < product.stock) {
      const newQuantity = quantity + 1;
      setQuantity(newQuantity);
      onQuantityChange(newQuantity);

      // Show SweetAlert only when quantity reaches 2
      if (newQuantity === 2) {
        Swal.fire({
          title: "Discount Added!",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
      }
    }
  };

  const handleInputChange = (e) => {
    let newValue = e.target.value.replace(/[^0-9]/g, ""); // Remove any non-numeric characters
    newValue = parseInt(newValue, 10);

    if (isNaN(newValue) || newValue < 1) {
      newValue = 1; // Set minimum value to 1
    } else if (newValue > product?.stock) {
      newValue = product?.stock; // Ensure it doesn't exceed stock
    }

    setQuantity(newValue);
    onQuantityChange(newValue);
  };

  return (
    <div>
      <div className="flex justify-start gap-3">
        <span
          className="btn border-gray-200 bg-white plus text-xl text-gray-800 hover:bg-gray-100 hover:border-gray-300 minus"
          onClick={decreaseQty}
        >
          -
        </span>
        <input
          type="text"
          className="form-control count bg-transparent border-2 text-center rounded w-[3rem] "
          value={quantity}
          onChange={handleInputChange}
        />
        <span
          className="btn border-gray-200 bg-white plus text-gray-800 hover:bg-gray-100 hover:border-gray-300 text-xl"
          onClick={increaseQty}
        >
          +
        </span>
      </div>
      <div className="flex justify-start gap-3">
      {
  // Display specific messages based on product ID and quantity
        product._id === "6632450450b3e83d0b476637" ? (
          quantity === 1 ? (
            <p>Buy 2 get 2nd Product for 10% Off</p>
          ) : quantity === 2 ? (
            <p>Buy 3 get 3rd Product for 30% Off</p>
          ) : (
            // Optional: Handle cases for quantity > 2 if needed
            <div>
              <p>
                Price you would have paid:{" "}
                <s className="text-red-500">₹ {actualPrice}</s>
              </p>
              <p>
                Discounted Price:{" "}
                <strong className="text-green-600">₹ {discountPrice}</strong>
              </p>
            </div>
          )
        ) : (
          quantity === 1 ? (
            <p>Buy 2 get 2nd Product for {product?.offer}% Off</p>
          ) : (
            // Display price and discount information for quantities greater than 1
            <div>
              <p>
                Price you would have paid:{" "}
                <s className="text-red-500">₹ {actualPrice}</s>
              </p>
              <p>
                Discounted Price:{" "}
                <strong className="text-green-600">₹ {discountPrice}</strong>
              </p>
            </div>
          )
        )
      }

      </div>
    </div>
  );
};

export default QuantitySelector;
