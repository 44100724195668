const banners = [
  {
    src: "https://ik.imagekit.io/1ebcdgswb/inherbz%20banner/banner-1.jpeg?updatedAt=1718216965456",
    link: "/products/6632450450b3e83d0b476637",
  },
  {
    src: "https://ik.imagekit.io/1ebcdgswb/inherbz%20banner/banner-2.jpeg?updatedAt=1718216965453",
    link: "/products/6642247f2caa481cc3839db8",
  },
  {
    src: "https://ik.imagekit.io/1ebcdgswb/inherbz%20banner/banner-3.jpeg?updatedAt=1718216965413",
    link: "/products/663245e150b3e83d0b476640",
  },
];

export default banners;
